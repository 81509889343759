/*html body {
    background: #f5f7f9;
    font-size: 1.4rem;
    font-family: Calibri;
    font-weight: 400;
    color: #656D78;
}
footer {
    background: #fff;
    padding: 15px 20px;
    display: block;
    text-align:center;
}
.x_panel {
    position: relative;
    width: 100%;
    margin: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.x_heading_wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-bottom: 16px;
}
.x_heading_wrapper .flex_item_heading {
    flex: 100 1 auto;
}
.x_heading_wrapper .flex_item_heading .flex_item_heading_right{
    flex: 0 1 auto;
}
.x_heading_wrapper .flex_item_heading .top_nav_breadcrumb {
    margin: 12px 0;
    display: block;
}
.x_heading_wrapper .flex_item_heading .top_nav_heading {
    padding: 4px 0;
    margin-bottom: 8px;
    font-size: 28px;
    display: flex;
    align-items: center;
}
.x_heading_wrapper .flex_item_heading .top_nav_heading .type {
    flex: 0 0 auto;
    vertical-align: middle;
    font-size: 0.5em;
    color: #80848f;
    font-weight: 600;
    padding: 2px 4px;
    margin-right: 6px;
    border-radius: 3px;
    border: 1px solid #dddee1;
    font-variant: small-caps;
    background: #f8f8f9;
}
.x_heading_wrapper .flex_item_heading .top_nav_heading .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    vertical-align: middle;
    flex: 1 1 auto;
}
.x_content {
    padding: 0 5px 6px;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    margin-top: 5px;
}
.container {
    width: 100%;
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
}
.x_heading_wrapper .flex_item_heading .breadcrumb{
    padding-left: 0px;
    background-color:white;
}*/

thead tr{
    background-color: rgb(248, 248, 249);
    color: rgb(73, 80, 96);
    font-size: 1.05em;
}
.table td {
    border-right: 1px solid #e6ebf5;
    border-bottom: 1px solid #e6ebf5;
    border-left: 1px solid #e6ebf5;
}
.table th {
    border-right: 1px solid #e6ebf5;
    border-left: 1px solid #e6ebf5;
    border-bottom: 1px solid #e6ebf5;
}
.table > thead:first-child > tr:first-child > th{
    border-top: 2px solid #ddd; 
}
.dl-horizontal dt{
    width:auto;
}
@media (min-width: 768px){
    .dl-horizontal dd {
        margin-left: 50px;
    }
}
label{
    font-size:1.2rem;
}

.searchListing {
    display:table-cell;
}

.listingRow {
    width:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
}

.form-horizontal .control-label {
    line-height:1;
    font-size:14px;
    color:#222;
    font-weight:bold;
}

.title_left span {
    font-size:14px;
}

.title_left h3 {
    font-size:26px;
}

.test-name span {
    font-family:Calibri !important;
    font-size:14px !important;
}

.test-name p {
    font-family:Calibri;
    font-size:14px;
    margin:0;
}

@media (max-width: 991px) {
    #no-more-tables tr {
        border:none !important;
        margin-bottom:15px;
    }   
}

@media (min-width: 991px) {
    .searchListing {
        width:19%;
    }

    .listingToggle {
        width:29%;
    }
}