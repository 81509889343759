@font-face {
    font-family:'Calibri';
    src: url('/public/fonts/Calibri.ttf');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
/*FROM OLD ACCOUNT CENTER*/
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}
.login-signup-container {
    max-width: 430px;
    background: #fff;
    border: solid 1px #dfdfdf;
    margin: 100px auto;
    overflow: hidden;
}
.login-signup-wrapper{
    margin-left: 15px;
    margin-right: 15px;
    margin-top:30px;
    margin-bottom:50px;
}
.login-logo-container {
  width:100%;
  margin-bottom:30px;
}
.login-logo {
    width: 300px;
    height: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left:auto;
    margin-right:auto;
}
.login-signup-container h4{
  margin: 0;
}
.login-type-button{
    width:100%;
    height: 48px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #e9e9e9;
    text-align: center;
    font-size: 18px;
}
@media (max-width: 768px){
    .login-type-button{
        font-size:14px;
    }
}
.login-type-button:focus {
  outline: none;
}
.login-type-button:hover{
    border: solid 1px #0d77bd;
}
.login-type-button.selected{
    border: solid 1px #0d77bd;
    color: #0d77bc;
}
.login-signup-container label{
    font-size: 14px;
    font-weight: bold;
    color: #989b9c;
}
.login-signup-container .form-control{
    height:48x;
}
.login-signup-container .form-group {
    margin-bottom: 15px;
}
.top-fold {
    padding-left:15px;
    padding-right:15px;
    margin-bottom: 20px !important;
    position: relative;
}
.top-fold h5 {
    margin-top: 3px;
    font-size: 12px;
    text-align: right;
}
.body-fold {
    padding: 0 15px;
}
.forgot-password {
    font-size: 12px;
}
.login-btn, .login-btn:active, .login-btn:focus, .login-btn:visited, .login-btn:hover {
    width:100%;
    margin-top: 10px;
    color: #fcfcfc;
    border-radius: 3px;
    background-color: #54c454;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
}
.terms-conditions-checkbox{
    margin-top:5px;
    margin-bottom:15px;
}
/*END FROM ACCOUNT CENTER*/
body {
  font-family: Calibri;
  background:#495060;
  //background:#f5f7f9;
}
.swal2-overlay{z-index:50000;} .swal2-container{z-index:50001;}
/*Profile Notification*/


.profile-update-notification {
  // Style of the notification itself
  position:relative;
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 16px;

  color: #222222;
  background: #ffffff;
  border-left: 5px solid #2192dd;
  -webkit-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.18);
  box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.18);

  .notification-img {
    color:#ffffff;
    width:24px;
    margin-right:5px;
  }
  .notification-title {
  }

  .notification-content {
  }
  a.close{
    position:absolute;
    top:0px;
    right:0px;
    color:#989b9c;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-active {
  padding-left: 10px;
  opacity: 0;
}
/*Cube CSS */
.cssload-thecube {
  width: 73px;
  height: 73px;
  margin: 0 auto;
  margin-top: 49px;
  position: relative;
  transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(43,160,199);
  animation: cssload-fold-thecube 2.76s infinite linear both;
    -o-animation: cssload-fold-thecube 2.76s infinite linear both;
    -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
    -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
    -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
    -o-transform: scale(1.1) rotateZ(90deg);
    -ms-transform: scale(1.1) rotateZ(90deg);
    -webkit-transform: scale(1.1) rotateZ(90deg);
    -moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
    -o-transform: scale(1.1) rotateZ(180deg);
    -ms-transform: scale(1.1) rotateZ(180deg);
    -webkit-transform: scale(1.1) rotateZ(180deg);
    -moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
    -o-transform: scale(1.1) rotateZ(270deg);
    -ms-transform: scale(1.1) rotateZ(270deg);
    -webkit-transform: scale(1.1) rotateZ(270deg);
    -moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s;
    -o-animation-delay: 0.35s;
    -ms-animation-delay: 0.35s;
    -webkit-animation-delay: 0.35s;
    -moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s;
    -o-animation-delay: 0.69s;
    -ms-animation-delay: 0.69s;
    -webkit-animation-delay: 0.69s;
    -moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s;
    -o-animation-delay: 1.04s;
    -ms-animation-delay: 1.04s;
    -webkit-animation-delay: 1.04s;
    -moz-animation-delay: 1.04s;
}



@keyframes cssload-fold-thecube {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
        75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
        100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-o-keyframes cssload-fold-thecube {
  0%, 10% {
    -o-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
        75% {
    -o-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
        100% {
    -o-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-ms-keyframes cssload-fold-thecube {
  0%, 10% {
    -ms-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
        75% {
    -ms-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
        100% {
    -ms-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-fold-thecube {
  0%, 10% {
    -webkit-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
        75% {
    -webkit-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
        100% {
    -webkit-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cssload-fold-thecube {
  0%, 10% {
    -moz-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
        75% {
    -moz-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
        100% {
    -moz-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
/* Absolute Center Spinner */
.cssload-thecube {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 5%;
  bottom: 0;
  right: 0;
}
/*Framework changes*/
@media (max-width: 991px){
  .container-fluid{
    padding-left:0px;
    padding-right:0px;
  }
}
/*Sidebar CSS*/
.nav-none .top_nav {
  margin-left: 0px;
}
.nav-none footer {
  margin-left: 0px;
}

//hidden left menu
.nav-none .left_col{
  display: none;
}
/*For profile view*/
p.form-control-static{
  color:#222;
  font-size:16px;
}
.profile_info{
    width:100%;
    display:block;
    padding-bottom:20px;
    color:#222222;
    padding-top:5px;
}
.profile{
  min-height:72px;
  width:100%;
}
.profile_pic{
  float:none;
  width:auto;
}
.badge_profile {
  height: 72px;
  width: 72px;
  text-align: center;
  vertical-align: middle;
  color:#222222;
  font-size:30px;
  font-weight: 900;
  border-radius: 50%; /* may require vendor prefixes */
  background: white;
  display:block;
  line-height:72px;
  margin:auto;
  border:1px solid grey;
}
/*Modal CSS*/
.modal-backdrop.fade.in{
  z-index:50000!important;
}
.modal-open{overflow:auto;padding-right:0 !important;}
body {
  padding-right: 0 !important;
}
.modal-title{
  color:#222;
}
/*gentelella panel*/
.x_title {
    border-bottom: 0px; 
    padding: 1px 5px 6px;
    margin-bottom: 10px;
}

/*Override nav-sm to show full sidebar even on mobile*/
@media (max-width: 991px)
{
  .nav-sm .container.body .top_nav .nav_menu .nav-none-logo{
    display:none;
  }
  .nav-none .container.body .top_nav .nav_menu .nav-none-logo{
    display:block;
    position:absolute;
    left:44vw;
    top:10px;
    width:40px;
    height:48px;
  }
  .nav-sm .profile{
    padding-bottom:70px;
  }
  .menu-container{
    padding-top:25px;
    width: 111px;
    height: 99px;
  }
  .menu-logo-wrapper{
    background:#495060;
    padding-top:13px;
    padding-bottom:50px;
    width: 120px;
  }
  .nav-sm .container.body .col-md-3.left_col {
      display: flex;
      margin-bottom: -5000000px;
      padding-bottom: 5000000px !important;
      overflow: hidden;
      background-color:#495060;
  }
  .nav-sm .container.body .right_col {
      display: block;
      margin-left: 0px;
  }
  .nav-sm .container.body .col-md-3.left_col {
    width: 120px;
    padding: 0;
    z-index: 9999;
    position: absolute;
  }
  .nav-sm .container.body .right_col {
      padding: 0px;
      margin-left: 0px;
      z-index: 2;
  }
  .nav-sm footer {
      margin-left: 0px;
  }
}
@media (min-width: 992px)
{
  .nav-none-logo{
    display:none;
  }
  .menu-container{
    padding-top:13px;
//    width:86px;
    height:74px;
    margin-left:-5px;
  }
  li.active .menu-container{
    margin-left:0px;
  }
  .menu-logo-wrapper{
    background:#495060;
    padding-top:12px;
    padding-bottom:50px;
    width: 95px;
  }
  .nav-sm .container.body .col-md-3.left_col {
      display: flex;
  }
  .nav-sm .container.body .right_col {
      display: block;
      margin-left: 95px;
  }
  .nav-sm .container.body .col-md-3.left_col {
    width: 95px;
    padding: 0;
    z-index: 9999;
    position: absolute;
  }
  .nav-sm .container.body .right_col {
      padding-top: 10px ;
      padding-bottom: 10px ;
      padding-bottom: 10px ;
      padding-left:0px;
      padding-right:10px;
      margin-left: 95px;
      z-index: 2;
  }
  .nav-sm footer {
      margin-left: 95px;
  }
  #stoppingModal .modal-dialog{
    width:80vw;
  }
}
.nav-sm .main_container .top_nav {
    display: block;
    margin-left: 0px;
    z-index: 2;
}
.nav-sm .profile {
  background:#495060;
  display: block;
  padding-bottom:50px;
}
.nav-sm .profile .badge_profile {
   width:48px;
   height:48px;
   line-height:48px;
   font-size:24px;
}
.nav-sm .profile .profile_info{
    width:100%;
    display:block;
    padding-bottom:0px;
    color:white;
    padding-top:5px;
}

/*Login Page CSS*/

/*Theme Ovverride*/
img.center {
    display: block;
    margin: 0 auto;
}
.login{
  background:white;
}

body.login{
  background:#f5f7f9;
}
.login_content {
    margin: 0 auto;
    padding: 25px 0 0;
    position: relative;
    text-align: left; 
    text-shadow: 0 1px 0 #fff;
    min-width: 280px;
}
.login_content h1, .login_content p{
    text-align:center;
}
.login_content form input[type="text"], .login_content form input[type="email"], .login_content form input[type="password"] {
  border: 2px solid #c8c8c8;
}
/*Pagination CSS*/
.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
    z-index: 2;
    background-color: white; 
    border-color: #ddd;
    color:black;
}
a.disabled {
  color:gray;
  pointer-events: none;
}
a.disabled:hover {
  color:gray;
}
a.page-link.disabled{
  color:gray;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
a.page-link.active{
  color:black;
}
/*Tests Page CSS*/
/*Custom CSS for tests/index.vue*/
.test-item {
    width: 100%;
    padding-bottom:10px;
    display: inline-block;
    min-height:70px;
}
.test-item-border{
  border-bottom: dotted 1px #d1d1d1;
}
.test-item h3 {
    margin-top: 0 !important;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}
.test-item p {
    font-size: 16px;
}
.test-action {
    display: inline-block;
    vertical-align: middle;
}

/*Test Page CSS Questions and Options CSS*/
.question-box {
    min-height:100px;
    display:block;
}
.question-box span.question-no{
  font-size: 16px;
  color:#222222;
  font-weight:bold;
}
.question-box span.question-more-info{
  font-size: 16px;
  color:#222222;
  font-weight:bold;
  margin-bottom:10px;
}
.question-box .question-desc{
  font-size: 16px;
  color:#222222;
}
.question-box .options {
    min-height:70px;
    margin-bottom:15px;
}
.question-box .options .option  {
    margin-bottom:5px;
}
.question-box .options .option .description  {
  padding: 5px;
  font-size: 16px;
  color:#222222;
  display:inline-block;
}
.question-box .options .option .status {
  padding-top:2px;
  text-align: center;
  vertical-align: middle;
  height:100%;
  max-width:40px;
}
.question-box .options .option .description.selected{
  background-color:#668cff;
  border-radius:5px;
  color:white;
}
.question-box .options .option .description.correct{
  background-color:green;
  border-radius:5px;
  color:white;
}
.question-box .options .option .description.wrong{
  background-color:red;
  border-radius:5px;
  color:white;
}
.question-box .options .option .description{
  font-size:14px;
}
.question-box .options .option .description.disabled{
  opacity:.5;
}
.paginate.fixed{
  position: fixed;
  text-align: center;
  padding-right:25px;
  width:100vw;
  bottom:60px;
  height:50px;
  vertical-align:middle;
}

.paginate{
  font-size:18px;
  height:48px;
  color:black;
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin:0; 
    height:100%;
    border-radius: 3px;
}
.paginate .pagination > li >a {
  border-left: solid 1px #ededed;
  border-right: solid 1px #ededed;
  min-width:50px;
  text-align:center;
  color:black;
}

.paginate .pagination > li >a.btn-back-and-next{
  width:110px;
}
.paginate .pagination >li >a.answered{
  background-color:blue;
  color:white;
  //border-color:blue;
}
.paginate .pagination >li >a.unanswered{
  //border-color:red;
  //color:black;
  //border-style:dashed;
}
.paginate .pagination >li >a.current-question-group{
  //border-color:red;
  //color:black;
  //border-style:dashed;
  background-color:#9b9f9f;
  color:white;
}

.arrow_box.answered {
  position: relative;
  border: 4px solid #blue;
}
.arrow_box.answered:after, .arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box.answered:after {
  border-bottom-color: blue;
  border-width: 10px;
  margin-left: -10px;
}
.arrow_box.answered:before {
  border-bottom-color: blue;
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box.unanswered {
  position: relative;
}
.arrow_box.unanswered:after, .arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box.unanswered:after {
  border-bottom-color: red;
  border-width: 10px;
  margin-left: -10px;
}
.arrow_box.unanswered:before {
  border-bottom-color: red;
  border-width: 10px;
  margin-left: -10px;
}




a:hover{
 cursor: pointer;
 cursor: hand;
}
/*TRAT DISABLE PAGINATION FOR NON TEAM LEADER*/
.pagination.disabled{
  opacity:.5;
}
.pagination.disabled >li >a{
  cursor:default !important;
}


/*
@media (min-width: 992px){
  .chatbar-visible .container.body .right_col{ 
    margin-right: 330px;margin-left:0; 
  }
  .chatbar-visible .container.body .col-md-3.left_col { 
    right:0; 
  }
  .top_nav .navbar-left{ 
    float:left; 
  }
  .chatbar-visible .main_container .top_nav{ 
    margin-left:0;margin-right:330px; 
  }
  .chatbar-visible footer{ margin-right:330px;margin-left:0; }
}
*/
span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
    background: #fff;
    border: 2px solid #5bc0de;
    
}
.wizard li.active span.round-tab i{
    color: #5bc0de;
}

span.round-tab:hover {
    color: #333;
    border: 2px solid #333;
}

.wizard .nav-tabs > li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #5bc0de;
    transition: 0.1s ease-in-out;
}

.wizard li.active:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 1;
    margin: 0 auto;
    bottom: 0px;
    border: 10px solid transparent;
    border-bottom-color: #5bc0de;
}

.wizard .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
}

    .wizard .nav-tabs > li a:hover {
        background: transparent;
    }

.wizard .tab-pane {
    position: relative;
    padding-top: 50px;
}

.wizard h3 {
    margin-top: 0;
}

@media( max-width : 585px ) {

    .wizard {
        width: 90%;
        height: auto !important;
    }

    span.round-tab {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard li.active:after {
        content: " ";
        position: absolute;
        left: 35%;
    }
}
/*Bounce Button*/
@-webkit-keyframes bounce {
        0% {
    transform: scale(1,1) translate(0px, 0px);
  }
  
  30%{
    transform: scale(1,0.9) translate(0px, 10px); 
  }

  75%{
    transform: scale(1,1.1) translate(0px, -10px); 
  }
  
 100% {
    transform: scale(1,1) translate(0px, 0px);
  }
}

.bounce {
  -webkit-animation: bounce 0.75s infinite;
}

/*Table*/

table th{
  padding:8px;
  color: #989b9c;
  font-size: 14px;
  text-transform:uppercase;
}
.title_left{
  width:70%!important;
  padding-bottom:10px;
}
.title_right{
  width:30%!important;
}
footer{
  background:white;
  font-size:11px;
  border-top: 1px solid #eee;
  color:#777;
}
//table

.table-action-mobile{
  float:right;
}
 #no-more-tables td.test-name{ 
  font-weight:700;
  width:300px;
  word-wrap: break-word;
 }
#no-more-tables .table{
  margin-bottom:0px;
}
#mobile-test-table label{
  margin-bottom:0px;
}
@media only screen and (min-width: 801px){
  tbody tr{
    height:58px;
  }
  .table > tbody > tr > td{
    padding-top:15px;
  }
}

@media only screen and (max-width: 991px) {
    
  .table-action-mobile{
    float:none;
  }
  td.actions{
    min-height:74px;
  }
  /* Force table to not be like tables anymore */
  #no-more-tables table, 
  #no-more-tables thead, 
  #no-more-tables tbody, 
  #no-more-tables th, 
  #no-more-tables td, 
  #no-more-tables tr { 
    display: block; 
  }
 
  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables{
    border-bottom: 2px solid #ddd;
  }
  #no-more-tables tr { border: 1px solid #ccc;margin-top:3px;}
  
  #no-more-tables td { 
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 50%; 
    white-space: normal;
    text-align:left;
  }
  #no-more-tables td.test-name{ 
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 0%; 
    text-align:center;
    font-color:black;
    font-weight:800;
    white-space: normal;
    width:100%;
  }
 
  #no-more-tables td:before { 
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
  }
 
  /*
  Label the data
  */
  #no-more-tables td:before { content: attr(data-title); }
}

.member-list.well{
  padding:0px;
  margin-bottom:0px;
}

//form 
#peer-eval-tab li a{
  color: #333;
  font-size: 14px;
}
#peer-eval-tab{
  padding: 0;
  margin: -20px -20px 0;
}
#peer-eval-tab li:before{
    content: '';
    width: 100%;
    height: 2px;
    background: #d2d2d2;
    position: absolute;
    left: 50%;
    top: 27px;
    margin: 0 auto;
}
#peer-eval-tab li.active:before{
    background: #1abed7;
}
#peer-eval-tab li.line-active:before{
    background: #d2d2d2;
}
#peer-eval-tab li:last-child:before{
  content: none;
}
#peer-eval-tab li a,
#peer-eval-tab li a:hover,
#peer-eval-tab li a:active,
#peer-eval-tab li a:focus,
#peer-eval-tab li a:visited{
    font-size: 14px;
    font-weight: 500;
  border-radius: 0;
  border: none;
  padding: 11px 25px;
  text-align: center;
  margin-right: 0;
  color: #d2d2d2;
  background: none;
  opacity: 1;
  cursor: default;
  pointer-events: none;
}
#peer-eval-tab li a u{
  margin: 0 auto;
  text-decoration: none;
  width: 34px;
  height: 34px;
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
  letter-spacing: -0.06em;
  background: #f9feff;
  border: solid 2px #d2d2d2;
  font-weight: bold;
}
#peer-eval-tab li.active a{
  background: none;
  color: #1abed7;
}
#peer-eval-tab li.active a u{
  border-color: #1abed7;
  color: #fff;
  background: #1abed7;
}
.btn-grey{
    color:#fff;
    background-color:#A9A9A9;
    border-color:#A9A9A9;
}
.btn-grey:hover{
    color:#fff;
    background-color:grey;
    border-color:grey;
}
.btn.disabled{
  opacity:.5;
}
label{
  font-size:1.2rem;
}


//new ui

.page-title{
  height:0;
  min-height:0px;
    padding:0;
}
.top_nav .nav_menu {
  background-color:rgb(247, 247, 247);
  border:none;
}
.toggle{
  padding-top:21px;
  width:40px;
  margin-left:5px;
}
.left_col{
  background:#656D78;
  //margin-top: 55px;
  width:100%;
}
.menu_section > ul {
    text-align: center;
    margin: 0;
    list-style: none;
}

.menu-logo{
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
  width:30px;
  height:36px;
  padding-top:10px;
}
.menu-img-wrapper{
  display: block;
}
.menu-img{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  width:36px;
  height:36px;
}

span.menu-text{
  display: block;
  font-size: 0.9em;
}

.nav.side-menu{
  background:#495060;
}
.nav.side-menu > li{
  padding: 4px;
  color:#FFFFFF;
}
.nav.side-menu > li > a{
  color:#FFFFFF;
  background:none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow:none;
}
.nav.side-menu > li:hover, .nav.side-menu > li.current-page:hover, .nav.side-menu > li.active:hover {
    background: #FFFFFF;
    color:#989b9c;
    cursor:pointer;
}
.nav.side-menu > li.current-page, .nav.side-menu > li.active {
  border-left: 4px solid #5cadff;
  margin-left: 0;
  border-right:none !important;
  background: #FFFFFF;
  color:#989b9c;
  padding-left:0;
}
.main_container .top_nav {
  display: block;
  margin-left: 0px;
}
//container for the right side
.right-container{
  box-sizing: border-box;
  padding: 10px;
  //background: #fff;
  border-radius: 4px;
}
.form-control{
  height:48px;
}
.form-control-feedback{
  line-height:32px;
}
.has-feedback-left .form-control-feedback {
    left: 0; 
}
.has-feedback-left .form-control {
    padding-left: 45px;
}
.form-horizontal .control-label {
    line-height: 24px;
}
table {
  font-size: 14px;
  color: #222222;
}
.test-listing-pagination li a {
  height: 48px;
  width: 48px;
  line-height: 30px;
  text-align: center;
}
.preview-image {
    max-width: inherit;
    background: white;
}
.preview-image, .actual-image {
    width: 100%;
    text-align: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    margin:0 auto;
}
.actual-image{
  background-color:#989b9c;
  color:white;
  width: 100px;
  height: 100px;
}
.remove-btn, .remove-btn:active, .remove-btn:visited, .remove-btn:focus, .remove-btn:hover {
    z-index: 3;
    top: -7px;
    right: -3px;
    opacity: 1;
    background: red;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    position:absolute;
}
.breadcrumb {
    padding: 0px 0px;
    margin-bottom: 22px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.breadcrumb > li {
    display: inline-block;
    font-size:14px;
    color:#222;
}
.breadcrumb > li + li::before {
    content: "> ";
    color: rgb(204, 204, 204);
    padding: 0px 5px;
}
ul, ol {
    margin-top: 0;
    margin-bottom: 11px;
}
ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

//for test
body.test{
  /*Do not display logo*/
  .nav-none-logo{
    display:none;
  }
  .non-test-nav{
    display:none;
  }
  .top_nav {
    margin-left: 0px;
  }
  footer {
    margin-left: 0px;
  }
  .nav-none .left_col{
    display: none;
  }
}

.test-topbar{
  height:100px;
}

//.test-topbar div{
//  height:inherit;
//}

//.test-topbar div.test-info-div{
//  height:inherit;
//  text-align: left;
//  padding-top:17px;
//}

.test-topbar .test-name{
  font-size: 18px;
  color: #FFFFFF;
  min-height: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display:block;
  margin: 9px 0;
  margin-bottom:0px;
}
//.test-topbar .course-name{
//  font-size: 14px;
//  height: 14px;
//  font-weight: normal;
//  font-style: normal;
//  font-stretch: normal;
//  line-height: normal;
//  letter-spacing: normal;
//  color: #FFFFFF;
//  display:block;
//  margin: 9px 0;
//  margin-top:0px;
//}
.test-topbar div.answers-div{
//  height:inherit;
  text-align:right;
//  padding-top:10px;
}
.test-topbar .answered-label{
  font-size: 12px;
  height:12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #989b9c;
  display:block;
  margin: 9px 0;
}
.test-topbar .answered-count{
  font-size: 18px;
  height:18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #FFFFFF;
  display:block;
  margin: 9px 0;
}
div.timer-div{
  text-align:center;
//  height:inherit;
//  padding-top:17px;
}
 div.timer-div div.clock{
  height:68px;
}
div.timer-div div.clock div.number-div{
  margin: auto;
  display:inline-block;
  height:40px;
  background-color:#FFFFFF;
  width:40px;
  border-radius:3px;
  vertical-align:middle;
}
div.timer-div div.clock div.number-div.big{
  margin: auto;
  display:inline-block;
  height:190px;
  background-color:transparent;
  width:auto;
}
div.timer-div div.clock div.number-div div.number{
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 45px;
  letter-spacing: normal;
  text-align:center;
}
div.timer-div div.clock div.number-div div.number.big{
  font-size: 120px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
div.timer-div div.clock div.time-over-div{
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b1717;
}
div.timer-div div.clock div.time-over-div.big{
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b1717;
}
div.timer-div div.clock div.label-div{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  width:100%;
  color:#FFFFFF;
  height:12px;
  margin-bottom:5px;
}
div.timer-div div.clock div.number-div div.label-div.big{
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #989b9c;
  height:auto;
  width:auto;
  margin-bottom:0px;
}
div.timer-div div.clock div.colon{
  display:inline-block;
  font-size: 40px;
  margin-right:2px;
  color:#FFFFFF;
}
div.timer-div div.clock div.colon.big{
  display:inline-block;
  position: relative;
  top: -15px;
  font-size: 80px;
  margin-right:2px;
}
div.timer-div div.clock.green div.number{
  color: #47a647;
}
div.timer-div div.clock.orange div.number{
  color: orange;
}
div.timer-div div.clock.red div.number{
  color: #7b1717;
}
div.timer-div div.clock.green div.colon.big{
  color: #47a647;
}
div.timer-div div.clock.orange div.colon.big{
  color: orange;
}
div.timer-div div.clock.red div.colon.big{
  color: #7b1717;
}
.progress{
  height:20px !important;
  width:100%;
  border-radius:3px;
  background-color:#ffffff;
}
.progress-bar{
    background-color: #47a647;
}
.progress-bar.green{
    background-color: #47a647;
}
.progress-bar.orange {
    background-color: orange;
}
.progress-bar.red {
    background-color: #7b1717;
}
@media (max-width: 991px){
  .test-topbar-wrapper{
    height:250px;
  }
  .test-topbar{
    margin-left:5px;
    padding-right:10px;
  }
  .test-topbar div.test-info-div{
    height:70px;
    text-align: center;
    margin-bottom:15px;
    padding-top:25px;
  }
  .test-topbar div.answers-div{
    height:70px;
    text-align:center;
    padding-top:15px;
  }
  .test-topbar .answered-count {
    margin-top:0px !important;
  }
  .test-topbar div.timer-div{
    text-align:center;
    height:70px;
    padding-top:15px;
  }
}
@media (min-width: 992px){
  .progress{
    width:190px;
    float:right;
  }
  .test-topbar{
    padding-right:30px;
  }
  .test-topbar div.test-info-div{
    padding-left:0px;
  }
  .test-topbar div.answers-div{
    padding-right:0px;
  }
}
.question-group-status-table td.action a{
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #0d77bd !important;
}
div.assume-leadership-div{
  margin-bottom:15px;
}
span.assume-leadership{
  color:#222222;
  font-size:14px;
  font-weight: bold;
  display:inline-block;
}
span.team-initial{
  border-radius:50%;
  width:24px;
  height:24px;
  background-color: #495060;
  line-height:26px;
  color:#ffffff;
  font-size: 14px;
  font-weight: bold;
  display:inline-block;
  text-align:center;
}
span.assume-leadership a{
  color:#0d77bd;
}
//buttons
.btn-action{
  height:48px;
  border-radius:3px;
  font-size: 18px;
  font-weight: bold;
}
.btn-success {
  border-radius: 3px;
  background-color: #47a647;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
}
.btn-secondary {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  border: solid 1px #ededed;
  line-height:33px;
}
x_panel{
  border-radius:3px;
}
/*Bar Chart*/
.svg-y-label{ 
  cursor:pointer;
}
/*Carousel Control for trat report*/
.carousel-control{
  width:auto;
  color:#2192DD;
}
.carousel-control.disabled{
  color:gray;
}
input,select{
  border-radius:3px!important;
}

.hidden{
  display:none;
}

//--------- edited on 04 May 2018 --------//
.form-horizontal {
    display:flex;
    align-items:center;
    vertical-align: center;
}

.form-horizontal .control-label {
    padding-top:0;
}

.form-group {
    margin-bottom:0;
}

.title_left {
    padding-bottom:20px;
}

.page-title .title_left h3 {
    margin:10px 0;
    font-weight: normal;
}

//--------- edited on 14 May 2018 --------//

.form-group {
    display:flex;
    flex-wrap:wrap;
    height:100%;
}

.form-control-feedback {
    margin-top:0;
    line-height: inherit;
    height:inherit;
    font-size:15px;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}

.has-feedback-left .form-control {
    padding-left:32px;
}

.has-feedback .form-control {
    padding-right:20px;
}

.form-control {
    height: 36px;
    border: solid 1px #e9e9e9;
}

label { 
    margin-bottom:0;
}

.form-horizontal {
    margin-bottom: 20px;
}

body { 
    color:#222;
}

.form-horizontal .control-label {
    color: #989b9c;
    padding-right:0;
    font-weight: normal
}

//--------15 may 18 --------//
.form-dropdown{
  overflow: hidden;
  position: relative;
}
.form-dropdown:after{
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #989b9c;
  position: absolute;
  top: 40%;
  right: 10px;
  content: "";
  z-index: 0;
 }
.form-dropdown select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.fa.fa-sort {
    margin-left:5px;
}

#no-more-tables {
    background:#fff;
    padding:0;
    border-radius:3px;
    border: solid 1px #ededed;
}

#no-more-tables a {
    color:#222 !important;
}

#no-more-tables th, #no-more-tables td {
    font-weight: normal;
    line-height: 1.2;
    padding: 15px 20px;
}

#no-more-tables td.test-name {
    font-weight: normal;
    width:auto;
}

.btn-action {
    height:36px;
    font-size:14px;
    line-height: 1.5;
}

.table > thead > tr > th {
    border-bottom: solid 2px #ededed;
    border-right: solid 1px #ededed;
}

.table > tbody > tr > td {
   border-right: solid 1px #ededed; 
    vertical-align: inherit;
}

.test-listing-pagination li a {
    height:inherit;
}

.form-control-static {
    width:100%;
}

@media (max-width: 991px){
  .col-xs-12{
    padding-left:0 !important; 
    padding-right:0 !important;
  }
}

//--------16 may 18 -----//

td span {
    line-height:1.2
}

.table-action-mobile {
    float:left;
}

.fa.fa-circle {
    font-size:10px;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  border-radius: 3px;
  box-shadow: 1px 1px 4px 0 rgba(202, 202, 202, 0.5);
  background-color: #9b9f9f;
  border: solid 1px #ededed;
}

.pagination > li > a, .pagination > li > span {
    color:#222;
    border: solid 1px #ededed;
}

.container-fluid {
    padding:0 25px;
}

ol.breadcrumb {
    margin:10px 0;
}

.table-bordered {
    background:#fff;
}

.table-bordered th {
    font-weight:normal;
    color:#222;
}

//------------17 may 18-------//
.btn-download { 
  background-color: #47a647;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
    border:0px;
}

.btn-preview { 
  background-color: #1d83c7;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
    border:0px;
}


//-------18 may 18 --------//
.form-control{
    resize: none !important;
}

.test-topbar-wrapper{
//  width:100vw;
//  height: 100px;
  background-color: #495060;
  color:#ffffff;
//  margin-left:-15px;
//  margin-right:-15px;
  box-shadow: -1px 1px 5px 0 rgba(202, 202, 202, 0.5);
  padding: 30px;
}

.course-name {
    margin-top:10px;
}

.courseName {
    margin-left:10px;
}

.progress {
    margin-bottom:0;
}

.test-topbar {
    height:100%;
}

.right-container {
    padding:10px 25px;
}

.row {
    margin:0;
}


//-------- 21 may 2018 ------//

 div.timer-div div.clock{
  height:100%;
}

.progress-bar.red {
    background-color: #47a647;
}

/*div.timer-div div.clock.red div.number {
    color: #47a647;
}*/

.flagQns:hover i{
    color:red;
}

.paginate .pagination > li > a.current-question-group {
    border:solid 1px #ededed;
    box-shadow:1px 1px 4px 0 rgba(202, 202, 202, 0.5);
    border-radius:3px;
    border-bottom-left-radius:4px;
    border-top-left-radius:4px; 
}

.paginate .pagination > li > a {
    width:48px;
    line-height: 30px;
    font-size:14px;
}

@media (max-width: 991px){
  .right_col{
    padding:0px !important;
  }
}

@media (min-width: 992px){
    .progress {
        width:310px;
    }
}

// ---------- 22 may 2018 --------//
.paginate .pagination > li > a.answered {
    background:rgba(29, 131, 199, 0.5);
}

@media (max-width: 991px){
  .test-topbar div.answers-div, .test-topbar div.test-info-div, .test-topbar div.timer-div{
    height:100%;
      margin:0;
      padding:0;
  }
    
//    .test-topbar-wrapper {
//        height:100%;
//    }
    
    .test-topbar .test-name {
        margin:0;
    }
    
    .test-topbar div.timer-div, .test-topbar div.answers-div {
        margin-top:20px;
    }
    
    .statusCons {
        padding:0;
    }
}

//---------24 may 2018 --------//
.question-group-status-table tr:first-child div { 
    border-top:0;
}
.question-group-status-table tr div { 
    border-top:1px solid #ededed;
}

.table-fixed thead {
  width: 100%;
}
.table-fixed tbody {
  height: 310px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  border-bottom-width: 0;
}

.paginate, .paginate .pagination > li > a {
    height:36px;
    line-height: 24px;
    min-width:48px;
}

//------- 30 May 2018 -----//
.description p {
    margin:0;
}

.question-box .options .option .description {
    padding:0;
}

.question-box .options .option .status {
    padding-top:0;
}


//----- 31 May 2018 ------//

.quizRC.right-container {
    padding:0 25px 30px 25px !important;
}

.badge {
    background-color: #495060;
}

@media (max-width: 991px){
    .test-topbar-wrapper {
        height:auto;
    }
    
    .quizRC.right-container {
        padding:0 25px 30px 25px !important;
    }
    
    .peerEvaluation .btn-secondary {
        margin:20px 0 10px 0;
    }
    
    .peerEvaluation .btn-success {
        margin:0 0 30px 0;
    }
}

//------- 01 June 2018 ------//

.peerHeader span{
    font-size:14px;
    padding:2px 5px;
    border-radius: 3px;
    background:#fff;
    border: solid 1px #e9e9e9;
    color: #989b9c;
    line-height: 15px;
}

.page-title .title_left .peerHeader h3{
    margin:0;
    display:inline-block;
    vertical-align: middle;
    padding-left:10px;
}

.peerSubTop {
    text-align: right;
    padding-top:20px;
}

.title_left.peerTitle {
    width:50% !important;
    padding-bottom:30px;
}

.peerQns {
    font-size:16px;
    font-weight:100;
    line-height: 1;
}

@media (max-width: 991px){
    .peerSubTop {
        padding-top:0;
        padding-bottom:30px;
        text-align: left;
        display:flex;
        flex-wrap: wrap;
    }
    .title_left.peerTitle {
        width:100% !important;
    }
    .peerEvaluation {
        padding-top:20px;
    }
    .peerLeftBtn {
        margin-right:2%;
    }
    
    .peerEvaluation .btn-secondary {
        margin:0 2% 0 0;
    }
}

//------- 4 Jun 18----//

.peerContainer .form-group:first-child {
    margin-bottom: 20px;
}

.label-danger {
    background:#fff;
    border:1px solid #7b1717;
    color:#7b1717;
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

 .quantity {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]
{
  -moz-appearance: textfield;
}

.quantity input {
//  width: 45px;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 40px;
    right:2px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #989b9c;
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 1px;
  border-bottom: 1px solid #eee;
    display:flex;
    justify-content: center;
    align-items: center;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
  display:flex;
    justify-content: center;
    align-items: end;
}

@media (max-width: 991px){
    .indexListing { 
        padding:10px 25px !important;
    }
    
    .filter-by {
        padding-left:0;
    }
    
    .indexDisplay {
        padding:0;
    }
}

//--------- 5 jun 2018 ---------//
.btn-info {
    border:none;
    border-radius: 3px;
    background-color: #1d83c7;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
}

.btn-appAttachBtn {
    border:none;
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #1d83c7;
    color: #1d83c7;
}

.btn-ApplicationAction:hover {
    border: solid 1px #0e5e94;
    color: #0e5e94;
}


//----- 6 june 2018 -----//
.question-group-status-table tr:first-child { 
    border-top:0;
}
.question-group-status-table tr { 
    border-top:1px solid #ededed;
}

table th {
    font-weight: 100;
    color:#222;
}

table th {
    font-weight: 100;
    color:#222;
}
.btn.btn-appAction[disabled] {
    background-color: #9b9f9f;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border:none;
    margin-top:10px;
    font-weight: 100;
    line-height:1.4;
}

.btn.btn-appAction {
    background-color: #47a647;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border:none;
    margin-top:10px;
    font-weight: 100;
    line-height:1.4;
}

.btn-appMyFileBtn, .btn-appAttachBtn {
    width:170px;
}

.btn-appMyFileBtn {
    border:1px solid #1d83c7;
}

.question-desc p {
    margin:0;
    font-family:'Calibri';
}

.table-fixed tbody {
    max-height:310px;
    height:auto;
}

.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

@media (max-width: 991px){
    .btn-appMyFileBtn, .btn-appAttachBtn { 
        width:47%;
        padding:0;
        font-size:13px;
    }
}

//------ 7 June 2018 ------//

.discussionContent {
    padding:0 30px;
}

//----- 8 June 2018 ----//
.has-error .form-control {
    border: solid 1px #ff0202;
}

.question-group-status-table tr div {
    border-top:none;
}

// ------ 14 june 2018 -----// 
.iconsTRAT {
  padding:0;width:auto;font-size:14px;
}

.clarificationBtn, .clarificationBtn:hover {
  background-color: #0d77bd;color:#fff;border:none;
}

// ------- 18 June 2018 ------- //

@media (min-width: 991px){
    .discussionTeachers {
      text-align: right;
    }
}

@media (max-width: 991px){
    .discussionTeachers button{
      width:100%;
    }
}

.btnHideResult {
  border-radius: 3px;
  background-color: #e69618;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff !important;
}

.btnShowResult {
  border-radius: 3px;
  background-color: #1d83c7;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff !important;
}

button.btnShowResult:hover, button.btnHideResult:hover, button.btnHideResult:visited, button.btnHideResult:active {
  color:#fff !important;
}

.label-success {
  border-radius: 3px;
  background-color: #6b896f;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  font-style:normal;
}

// -------- 19 June 2018 ----- // 

.showHideResult, .votingButton {
  padding:20px 20px 0!important;
}

.showHideResult button, .votingButton button{
  margin:0;
}

@media (max-width: 991px){
    .eGalleryVote {
      padding-left:20px !important;
      padding-right:20px !important;
    }
    .eGalleryBtns {
      padding:0 20px 15px !important;
    }
}

@media (min-width: 991px){
    .eGalleryBtns {
      padding:15px 20px!important;
    }
}

.btn-cancelEGallery, .btn-cancelEGallery:hover, .btn-cancelEGallery:visited, .btn-cancelEGallery:active {
  border-radius: 3px;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  background-color: #ffffff;
  border: solid 1px #ededed;
  color:#000;
}

.eGalleryVote input[type="radio"]:checked+span {
  color: #47a647;
}

.clearBtn {
  background:none;
  border:0;
}

.btnPause, .btnPause:hover, .btnPause:visited, .btnPause:active {
  border-radius: 3px;
  background-color: #e69618;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
}

.btnRestart, .btnRestart:hover, .btnRestart:visited, .btnRestart:active {
  border-radius: 3px;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  background-color: #ffffff;
  border: solid 1px #ededed;
  color:#e69618
}

.stopVotingButton{
  padding:20px 20px 0!important;
}

.stopVotingButton button, .stopVotingButton button:active, .stopVotingButton button:visited, .stopVotingButton button:hover{
  border-radius: 3px;
  background-color: #b40020;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
}

//--------- 20 June 2018 -------//
.btnViewComments, .btnViewComments:hover, .btnViewComments:visited, .btnViewComments:active {
  border-radius: 3px;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  background-color: #ffffff;
  border: solid 1px #ededed;
  color:#000;
}

.releaseResultButton, .hideResultButton, .resumeEgalleryButton{
  padding:20px 20px 0!important;
}

.releaseResultButton button, .releaseResultButton button:hover, .releaseResultButton button:visited, .releaseResultButton button:active {
  border-radius: 3px;
  background-color: #1d83c7;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
  margin:0;
}

.hideResultButton button, .hideResultButton button:hover, .hideResultButton button:visited, .hideResultButton button:active {
  border-radius: 3px;
  background-color: #1d83c7;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
  margin:0;
}

.resumeEgalleryButton button, .resumeEgalleryButton button:hover, .resumeEgalleryButton button:visited, .resumeEgalleryButton button:active {
  border-radius: 3px;
  background-color: #1d83c7;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
  margin:0;
}

.sticky-col {
  position:absolute;
  top:0;
  left:0;
  width:192px;
}

// --------- 22 June 2018 -------//

.btnRankSubmit, .btnRankSubmit:visited, .btnRankSubmit:active, .btnRankSubmit:hover, .btnBestSubmit, .btnBestSubmit:visited, .btnBestSubmit:active, .btnBestSubmit:hover {
  border-radius: 3px;
  background-color: #47a647;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
}

.btnRankSubmit:disabled, .btnBestSubmit:disabled {
  border-radius: 3px;
  background-color: #9b9f9f;
  box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
  color:#fff;
}

.modal-dialog textarea, .modal-dialog textarea:focus {
  resize:none;
  height:150px;
  border: solid 1px #ededed;
  outline:none;
}

textarea::-moz-placeholder, textarea:-moz-placeholder, textarea::-webkit-input-placeholder, textarea:-ms-input-placeholder  {
    color: #9b9f9f;
    font-size:14px;
    font-weight: 100;
    font-family: Calibri;
    font-style:italic;
    font-style: oblique;
}

/*@media (max-width: 991px){
  .qnsSection {
    margin-top:137px;
  }
}

@media (min-width: 991px){
  .qnsSection {
    margin-top:86px;
  }
}*/

// --------- 26 June 2018 -------//
.claBtn, claBtn:visited, claBtn:active, claBtn:hover{
  border-radius: 3px;
  background-color: #47a647;
  color:#fff;
}

//-------- 27 June 2018 ------//
.statusBar .x_panel {
  background:none;
  border:none;
}

.statusCons {
  background:#fff;
  border:1px solid #E6E9ED;
}

@media (min-width: 991px){
  .viewClarificationBtn {
    padding:0;
  }
}

@media (max-width: 991px){
  .viewClarificationBtn {
    padding:0 20px !important;
    margin-bottom:20px;
  }
}

.clarifiQns .quesNo {
  font-weight:bold;
  color:#222;
}

//------- 28 Jun 2018 -----//
i.fa-sort-desc, i.fa-sort-asc{
  height: 8px;
  position: relative;
}

i.fa-sort-desc:before{
position: relative;
top: 50%;
    transform: translateY(-50%);
    display: block;
}

i.fa-sort-asc:before {
position: relative;
top: 50%;
    display: block;
}

/*p {
  white-space:pre-wrap;
  word-wrap:break-word;
}*/








