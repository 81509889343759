// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'node_modules/gentelella/vendors/font-awesome/scss/font-awesome';

@import 'node_modules/gentelella/build/css/custom.min';
@import 'node_modules/sweetalert2/dist/sweetalert2.min';
@import 'node_modules/gentelella/vendors/bootstrap-daterangepicker/daterangepicker.scss';
@import './kr';
@import './framework';